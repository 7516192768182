<template>
  <div v-if="notBlocked" :class="'grid grid-template-areas xl:grid-template-areas-desktop min-h-screen flex-1'">

    <!--/ Header /-->
    <router-view name="header" v-model:sidebarOpen="sidebarOpen" class="grid-area-header" />

    <!--/ Content /-->
    <main class="grid-area-main h-auto flex justify-center md:max-w-screen-xl justify-self-center w-full" :class="[layoutClass(), {'desktop': sidebarOpen && sidebarDesktop, 'tablet': sidebarOpen && sidebarTablet, 'mobile': sidebarOpen && sidebarMobile}, {'billing-message': (current_shop && !current_shop.subscription) || (not_paid > 0)}]">
      <flash :flash="flash" :class="{'show-flash': flash}" v-if="flash" />
      <router-view :key="$route.fullPath" v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </main>

    <!--/ Sidebar /-->
    <router-view v-if="current_shop" v-model:sidebarOpen="sidebarOpen" name="sidebar" :sidebar-open="sidebarOpen" class="grid-area-sidebar" />

    <modal v-if="modal" v-bind="modal" />
    <modal_delete v-if="modal_delete" v-bind="modal_delete" />
  </div>
  <div v-else>
    <h1>Your store is not active. Please contact support</h1>
  </div>
</template>


<script>
  import {setupAxios} from "@/admin/application/axios.js";
  import "./styles.css.scss"
  import "./admin-v3.css.scss"

  import moment from 'moment'
  import momenttz from 'moment-timezone'

  import Base from './mixins/base.js'
  import flash from './components/flash.vue'
  import modal from './components/helpers/modal.vue'
  import modal_delete from './components/helpers/modal-delete.vue'
  export default {
    name: 'app',
    components: {flash,modal,modal_delete},
    mixins: [Base],
    data: ()=>({
        request_sequence: 0,
        current_requests: [],
        current_requets: [],
        keymaster: {},
        current_shop: null,
        data_language: null,
        current_user: null,
        possible_admin_locales: [],
        localeValue: "en-US",
        shops: [],
        flash: null,
        modalValue: null,
        modal_delete: null,
        currencies: [],
        page: { search: {} },
        got_new_assets: false,
        old_assets_version: null,
        redirect_to: null,
        busy_timeout: null,
        previous_busy: null,
        sidebarDesktop: false,
        sidebarTablet: false,
        sidebarMobile: false,
        sidebarOpen: false,
        keymasterTimeout: null,
        isRouterAlive: true,
        not_paid: 0,
        lshops: [],
    }),
    computed: {
      notBlocked() {
        if (this.$route.name && this.$route.name.startsWith("billing")) { return true; }

        if (this.current_shop) {
          return !this.current_shop.blocked;
        } else {
          return true;
        }
      },
    },
    watch: {
      current_user(value, was){
          if (!this.keymaster || !!value !== !!was)
              this.update_keymaster()
          if (value && value !== was)
              this.update_3rdparty_js()
      },
      current_shop(value, was){
          if (!this.keymaster || value !== was)
              this.update_keymaster()
          if (value && value !== was){
              this.update_3rdparty_js()
              if ("id" in value)     window.localStorage.shop_id     = value.id
              if ("domain" in value) window.localStorage.shop_domain = value.domain
              if ("possible_languages" in value){
                if (!value.possible_languages.includes(this.data_language)){
                  if ("default_language" in value) this.data_language = value.default_language
                }
              }
          }
      },
      'current_shop.time_zone'(time_zone){
          moment.tz.setDefault(time_zone)
      },
      '$route'(to, from){
        if (this.got_new_assets){
            this.got_new_assets = false
            window.location = window.location
        }
      },
    },
    created(){
      window.app = this
      setupAxios(this)

      // sets whether or not locales are guessed from key string or not based on environment
      if (['staging', 'development', 'test'].includes(import.meta.env.MODE))
        window.I18n.missingBehaviour = 'strict' // strict, message, guess
      else
        window.I18n.missingBehaviour = 'guess'

      this.loadCrisp()
    },
    mounted(){
      this.$nextTick(()=>{
        window.addEventListener('click', ((e) =>{
          this.setSidebar(true)
          this.closeAllDropDowns(e)

          if (this.page.search?.results?.length > 0) console.log("this.page.search", this.page.search)
          if (this.page.search) this.$set(this.page.search, 'results', [])
          if (e.target.classList.contains('link-opener'))
              this.setSidebar(false)
        }), false)
      })
      setTimeout(this.checkUnpaidInvoices, 300);
      $crisp.push(["do", "chat:hide"])
      $crisp.push(["on", "chat:closed", ()=> $crisp.push(["do", "chat:hide"])])
      $crisp.push(["on", "message:received", ()=> $crisp.push(["do", "chat:show"])])
    },
    methods:{
      oldClass() {
        return `admin-${this.$route.name || ''}`
      },
      layoutClass() {
        const default_class   = ""
        const full_page_class = ""
        if (this.$route.matched[0] && this.$route.matched[0].components.sidebar)
          return default_class
        else
          return full_page_class
      },
      update_keymaster(){
          clearTimeout(this.keymasterTimeout)
          this.keymasterTimeout = setTimeout(()=>
              this.axios.get(`/keymaster`).then((resp)=>this.keymaster=resp.data)
          , 100)
      },
      loadCrisp(){
        window.$crisp=[];

        window.CRISP_WEBSITE_ID=process.env.CRISP_WEBSITE_ID;
        if (!window.CRISP_WEBSITE_ID) return
        (function(){
          const d=document;
          const s=d.createElement("script")
          s.src="https://client.crisp.chat/l.js";
          s.async=1;
          d.getElementsByTagName("head")[0].appendChild(s);
        })();
      },
      //statuses ["not_paid", "paid", "voided", "pending", "payment_due"]
      async checkUnpaidInvoices(){
        if (this.$route.name === "authenticate") return
        try{
          const {data} = await this.axios.get("/subscriptions/our-invoices", {params: {where: "status:not_paid|payment_due", per: 0}})
          this.not_paid = data.pagination.total
        }catch (e) {}
      },
    },
  }
</script>
